import axios from "axios"

function string(key, value) {
    logDebug(key + ': ' + value)
}

function stringKey(key) {
    logDebug(key)
}

function json(key, value) {
    logDebug(key,value)
}

/** use for global vue.js global error handling */
function logVueError(err, vm, info) {
    logError(err, {vm, info});
    sendLogToServer('Error', err.message, {...info, componentName: vm.$options.name});
}

/** 
 * Log debug message
 * @param {string} message - message to log
 * @param {object} context - any object e.g Error object
 */
function logDebug(message, context) {
    if (process.env.VUE_APP_LOG_DEBUG !== 'true') return
    logInternal(context, 'Debug', message)
}

/** Log information message
 * @param {string} message - message to log
 * @param {object} context - any object e.g Error object
 */
function logInfo(message, context) {
    if (process.env.VUE_APP_LOG_INFO !== 'true') return
    logInternal(context, 'Information', message)
}

/** Log Error message
 * @param {string} message - message to log
 * @param {object} context - any object e.g Error object
 */
function logError(message, context) {
    if (process.env.VUE_APP_LOG_ERROR !== 'true') return
    logInternal(context, 'Error', message)
}

/** Log Warning message
 * @param {string} message - message to log
 * @param {object} context - any object e.g Error object
 */
function logWarn(message, context) {
    if (process.env.VUE_APP_LOG_WARN !== 'true') return
    logInternal(context, 'Warning', message)
}

function logInternal(context, level, message) {

    let currentTime = new Date();

    console.log(`${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()} ${level}: ${message}`, context || '');

    sendLogToServer(level, message, context);
}

/**
 * Sends a log event to the server.
 *
 * @param {string} logLevel - The log level of the event. One of Error, Information, Warning, Debug.
 * @param {string} errorMessage - The error message associated with the event.
 * @param {object} additionalProperties - Additional properties to include in the log event.
 */
function sendLogToServer(logLevel, errorMessage, additionalProperties) {
    const logEvent = {
        timestamp: new Date().toISOString(),
        level: logLevel,
        renderedMessage: errorMessage,
        properties: {
            ...(additionalProperties || {}),
            environment: process.env.NODE_ENV,
            baseUrl: process.env.BASE_URL
        }
    };

    axios.post(process.env.VUE_APP_LOG_URL, logEvent, {
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch(error => {
        console.log('Error sending log event to server:', error);
    });
}

export {
    string,
    stringKey,
    json,
    logVueError,
    logInfo,
    logError,
    logWarn,
    logDebug
}
